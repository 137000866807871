<template>
  <nav :class="['navbar', 'navbar-expand-md', navbarColorClass]">
    <div class="container-fluid">
      <a class="navbar-brand" @click="router.push({ name: 'home' })"> <img class="app-icon" :src="iconSrc"
          alt="Crosstown Logo" /> {{user.account_name}} Foundry</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <a :class="`nav-link ${routepath.startsWith('/news') ? 'active' : ''}`" aria-current="page"
              @click="router.push({ name: 'news' })"><i class="bi bi-newspaper"></i> News</a>
          </li>
          <li class="nav-item">
            <a :class="`nav-link ${routepath.startsWith('/prints') ? 'active' : ''}`" aria-current="page"
              @click="router.push({ name: 'prints' })"><i class="bi bi-printer"></i> Prints</a>
          </li>
          <li class='nav-item' v-if="user.role === 'admin'">
            <a :class="`nav-link btn mx-1 ${adminColorClass} ${routepath.startsWith('/mixins') ? 'active' : ''}`" aria-current="page"
              @click="$router.push({ name: 'mixins' })"><i class="bi bi-puzzle"></i> Mixins</a>
          </li>
          <li class='nav-item' v-if="user.role === 'admin'">
            <a :class="`nav-link btn mx-1 ${adminColorClass} ${routepath.startsWith('/users') ? 'active' : ''}`" aria-current="page"
              @click="router.push({ name: 'users' })"><i class="bi bi-people"></i> Users</a>
          </li>
        </ul>
        <form class="d-flex">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="menuDropdown" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img :src="avatarUrl" width="25" alt="User Avatar" />
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="menuDropdown">
              <h6 class="dropdown-header text-warning">
                <img :src="avatarUrl" width="25" alt="User Avatar" /> {{ user.name ? user.name : ''}}
              </h6>
              <b v-if="user.account_name" class="dropdown-header">{{user.account_name }} {{ user.city_name }}</b>
              <b v-else class="dropdown-header">Account not set</b>
              <li><a class="dropdown-item" @click="onProfileButtonClick">Profile</a></li>
              <li><a class="dropdown-item" @click="router.push('/logout');">Sign out</a></li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';

import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();

import gravatar from 'gravatar';
import { useUserStore } from '../stores/user';

const userStore = useUserStore();
// Create a reactive reference to store the user data
const user = ref(userStore.user);

const avatarUrl = computed(() => {
  return gravatar.url(user.email, { protocol: 'https', s: '100', r: 'pg', d: 'retro' });
});

// Watch for changes in the user property
watch(
  () => userStore.user,
  (newUser) => {
    // Update the user reference with the new value
    user.value = newUser;
  }
);

const navbarColorClass = ref('crosstown-nav');
const iconSrc = ref('/crosstown-dark.svg');
const adminColorClass = ref('crosstown-admin-dark-btn');

const routepath = computed(() => {
  return route.path;
});

onMounted(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  navbarColorClass.value = prefersDarkTheme.matches ? 'crosstown-dark-nav' : 'crosstown-nav';
  iconSrc.value = prefersDarkTheme.matches ? '/crosstown-dark.svg' : '/crosstown-light.svg';
  adminColorClass.value = prefersDarkTheme.matches ? 'crosstown-admin-btn' : 'crosstown-admin-dark-btn';
}

function onProfileButtonClick(row) {
  router.push({ name: 'userProfileEdit' });
}
</script>

<style scoped>
.app-icon {
  width: 32px;
  padding: 2px;
}

.crosstown-nav {
  background-color: rgb(255, 162, 56) !important;
}

.crosstown-dark-nav {
  background-color: rgb(197, 105, 0) !important;
}

.crosstown-admin-dark-btn {
  background-color: rgb(197, 105, 0) !important;
  color: #ffffff !important;
}
.crosstown-admin-btn {
  background-color: rgb(255, 162, 56) !important;
  color: #000000a6 !important;
}
.nav-item,
.navbar-brand {
  cursor: pointer;
}
</style>