<template>
  <main>
    <div role="main">
      <MixinBrowser :account_id="user.account_id"/>
    </div>
  </main>
</template>

<script setup>
import { onMounted } from 'vue';
import MixinBrowser from '../components/MixinBrowser.vue';
import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

onMounted(() => {
  document.title = 'Foundry Mixins';
});
</script>
