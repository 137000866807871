<template>
  <div v-if="print && print.publications && print.publications.neighborhoods && neighborhoodMap">
    <div v-if="print.publications.neighborhoods.length > 0">
      <div v-if="sendToSelfResult" class="mt-2 alert alert-info alert-dismissible fade show" role="alert">
        Email sent to {{user.email}}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="sendToSelfResult = null"></button>
      </div>
      <div v-else>
        <button v-if="!sendingToSelf" class="btn btn-secondary" @click="sendToSelf">
          <i class="bi bi-envelope"></i> Email me {{
            neighborhoodMap.get(print.publications.neighborhoods[0].id) }} newsletter
        </button>
        <button v-else class="btn btn-secondary">
          <span v-if="sendingToSelf" class="spinner-border spinner-border-sm"></span> Sending...
        </button>
      </div>
    </div>

    <div v-if="print.publications.neighborhoods.every(d => d.index !== '')">
      <div class="mt-3 card border-danger p-3" :class="print.send_info ? 'border-info' : 'border-danger'">
        <div v-if="print.send_info">
          <div v-if="print.send_info.end_timestamp">
            Sent to subscribers on {{ formatDate(print.send_info.end_timestamp) }}
          </div>
          <div v-else>
            Sending to subscribers
            <div class="progress ml-2 w-25">
              <div class="progress-bar" role="progressbar"
                :style="{ width: isNaN((print.send_info.count / print.send_info.total) * 100) ? 0 : ((print.send_info.count / print.send_info.total) * 100).toFixed(1) + '%' }"
                :aria-valuenow="isNaN((print.send_info.count / print.send_info.total) * 100) ? 0 : ((print.send_info.count / print.send_info.total) * 100).toFixed(1)" :aria-valuemin="0"
                :aria-valuemax="100">{{ isNaN((print.send_info.count / print.send_info.total) * 100) ? 0 : ((print.send_info.count / print.send_info.total) * 100).toFixed(1) }}%</div>
            </div>
          </div>
          <div class="mt-2">
            <code v-if="sendSubscribersOutput" class="text-muted">{{ sendSubscribersOutput.value }}</code>
          </div>
        </div>
        <div v-else>
          <h4 class="text-danger">Email Subscribers</h4>
          <div class="my-2">
            Enter '{{ print.id }}' to email newsletters to subscribers
          </div>
          <form class="form-inline">
            <input class="form-control mb-2" type="text" v-model="sendSubscribersVerificationCode"
              placeholder="Enter print id">
          </form>
          <button class="btn btn-danger mt-2" :disabled="sendSubscribersVerificationCode !== print.id"
            @click="onSendToSubscribers()">Send</button>
        </div>
      </div>

    </div>
    <div class="mt-2" v-else>
      All {{ neighborhoodMap.size - 1 }} neighborhoods publications are required to send to subscribers
    </div>
  </div>
  <div v-else>There are no publications to send</div>
</template>

<script setup>
import { api, authHeaders } from '../api';
import { ref, onMounted, watch } from 'vue';
import dayjs from 'dayjs';

const REFRESH_SEND_INFO_INTERVAL = 20000;

const props = defineProps({
  modelValue: Object,
});

const print = ref(undefined);
const sendToSelfResult = ref('');
const sendingToSelf = ref(false);
const sendSubscribersVerificationCode = ref('');
const sendSubscribersOutput = ref('');
const neighborhoodMap = ref(undefined);
const timer = ref(undefined);

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

function formatDate(date) {
  return date ? dayjs(date).format(import.meta.env.VITE_DATETIME_FORMAT) : 'NA';
}

async function sendToSelf() {
  sendingToSelf.value = true;
  const result = await api.post(
    'mailer/send',
    { print_id: print.value.id, emails: [user.email], neighborhoods: print.value.publications.neighborhoods[0].id },
    authHeaders()
  );
  sendingToSelf.value = false;
  sendToSelfResult.value = result.data;
};

async function onSendToSubscribers() {
  // console.log('coming soon');
  // return;
  print.value.send_info = {};
  sendSubscribersVerificationCode.value = '';
  const result = (await api.post('mailer/send-subscribers', { print_id: print.value.id }, authHeaders())).data;
  sendSubscribersOutput.value = `${result.message} ${result.error ? '' + result.error.message : ''}`;
  timer.value = setInterval(refreshSendInfo, REFRESH_SEND_INFO_INTERVAL);
}

async function refreshSendInfo() {
  const result = (await api.get('prints/' + print.value.id, authHeaders())).data;
  print.value = result;
  if (print.value?.send_info?.end_timestamp) {
    clearInterval(this.timer);
  }
  // this.$emit('change', this.print);
}

watch(
  () => print,
  (print) => {
    this.print = print;
  }
);

// watch: {
//   'data.print': function (print) {
//     this.print = print;
//   }
// }

watch(() => props.modelValue, (newValue) => {
  print.value = newValue;
});

onMounted(async () => {
  console.log('props', props);

  print.value = JSON.parse(JSON.stringify(props.modelValue));

  console.log(print.value);

  if (print.value.send_info && !print.value.send_info.end_timestamp) {
    refreshSendInfo();
    timer.value = setInterval(refreshSendInfo, REFRESH_SEND_INFO_INTERVAL);
  }

  if (print.value.city) {
    const neighborhoods = (await api.get(`prints/neighborhoods/${print.value.city}`, authHeaders())).data;
    neighborhoodMap.value = new Map();
    neighborhoods.forEach((d) => {
      neighborhoodMap.value.set(d.id, d.name);
    });
  }
});
</script>

<style scoped>
code.trace {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: normal;
  /* min-height: 100px; */
  margin: 0 0 0 0px;
  padding: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  font-size: 0.72em;
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-all;
}
</style>