import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import LoginWithCode from '../views/LoginWithCode.vue'
import Logout from '../views/Logout.vue'
import Home from '../views/Home.vue'
import Mixins from '../views/Mixins.vue'

import News from '../views/News.vue'
import NewsEdit from '../views/NewsEdit.vue'
import NewsAdd from '../views/NewsAdd.vue'

import Users from '../views/Users.vue'
import UsersAdd from '../views/UsersAdd.vue'
import UsersEdit from '../views/UsersEdit.vue'
import UserProfileEdit from '../views/UserProfileEdit.vue'
import api from '../api'

const routes = [
  { path: '/newsEdit/:id', name: 'newsEdit', component: NewsEdit, meta: { requiresAuth: true } },
  { path: '/newsAdd', name: 'newsAdd', component: NewsAdd, meta: { requiresAuth: true } },
  { path: '/news', name: 'news', component: News, meta: { requiresAuth: true } },
  { path: '/mixins', name: 'mixins', component: Mixins, meta: { requiresAuth: true } },
  { path: '/prints', name: 'prints', component: () => import('../views/Prints.vue'), meta: { requiresAuth: true } },     
  { path: '/print/:id', name: 'print', component: () => import('../views/Print.vue'), meta: { requiresAuth: true } },     
  { path: '/printAdd', name: 'printAdd', component: () => import('../views/PrintAdd.vue'), meta: { requiresAuth: true } },     
  { path: '/datasets', name: 'datasets', component: () => import('../views/Datasets.vue'), meta: { requiresAuth: true } },
  { path: '/home', name: 'home', component: Home, meta: { requiresAuth: true } },
  { path: '/login', name: 'login', component: Login, meta: { guest: true } },
  { path: '/loginCode', name: 'loginCode', component: LoginWithCode, meta: { guest: true } },
  { path: '/users', name: 'users', component: Users, meta: { requiresAuth: true } },
  { path: '/usersAdd', name: 'usersAdd', component: UsersAdd, meta: { requiresAuth: true } },
  { path: '/usersEdit/:email', name: 'usersEdit', component: UsersEdit, meta: { requiresAuth: true } },
  { path: '/userProfileEdit', name: 'userProfileEdit', component: UserProfileEdit, meta: { requiresAuth: true } },
  { path: '/logout', name: 'logout', component: Logout, meta: { guest: true } },
  { path: '/', name: 'default', component: Login, meta: { guest: true } },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: Logout, meta: { guest: true } }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('foundry.user.token')) {
      next({
        path: '/',
        // params: { nextUrl: to.fullPath }
      })
    } else {
      api.post('users/token-valid', {
          token: localStorage.getItem('foundry.user.token')
        })
        .then((res) => {
          if (res.data.tokenExpired) {
            localStorage.removeItem('foundry.user.token');
            next({ path: '/' });
          }
        });

      next()
    }
  }
  else {
    next()
  }
})
export default router
