<template>
  <main v-if="user" role="main">

    <!-- <ToastComponent :show="showToast" title="Save" :message="message"/> -->

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/users">Users</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ user.email }} ({{ user.role }})</li>
      </ol>
    </nav>

    <div class="mb-2">
      <div class="mb-2 row">
        <div class="col-md-4">
          <label>Name</label>
          <input v-model="user.name" type="text" class="form-control">
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <label>Password</label>
          <input v-model="user.password" type="text" class="form-control" autocomplete="new-password">
          <div class="form-text">Leave empty not to update</div>
        </div>

        <div class="col-md-4 mt-3">
          <div v-if="user.password && !isSecurePassword" class="form-text text-danger">Must be at least 8 characters long
            and contain at least one uppercase letter, one lowercase letter, and one number.</div>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div class="row">
        <div class="col-md-4">
          <label>Role</label>
          <select v-model="user.role" class="form-select">
            <option v-for="option in roleOptions" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div class="row">
        <div class="col-md-4">
          <label>Account</label>
          <select v-model="user.account_id" class="form-select">
            <option v-for="option in accountOptions" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <label class="me-2 form-text text-danger">🚨 Can send for only authorized Crosstown LA users</label>
      <input v-model="user.can_send" type="checkbox" class="form-check-input" maxlength="50">
    </div>

    <div v-if="message" class="alert alert-danger show" role="alert">
      {{ message }}
    </div>

    <button class="btn btn-secondary" size="sm" :disabled='!dirty || !isSecurePassword' @click="onSave()">Save</button>

    <div class="card mt-3 border-danger p-3">
      <h4 class="text-danger">Danger zone</h4>
      <div class="my-2">
        Deleting is forever, to proceed enter <q>{{ user.email }}</q>
      </div>
      <form class="form-inline">
        <input class="form-control mb-2" type="text" v-model="verifyCode" placeholder="Enter id">
      </form>

      <button class="btn btn-danger" :disabled="verifyCode !== user.email" @click="onDelete()">Delete</button>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted, watchEffect, computed } from 'vue';
import { api, authHeaders } from '../api';
// import ToastComponent from './Toast.vue';

import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

//const showToast = ref(false);

const verifyCode = ref('');
const dirty = ref(false);

const user = ref(null);
let originalUser = null;

const roleOptions = ref([]);
const accountOptions = ref([]);

let accounts = [];
let cities = [];
const message = ref('');

function onDelete() {
  api.delete('users/' + user.value.email, authHeaders()).then(() => {
    router.push('/users');
  });
}

function getCity(city_id) {
  const city = cities.find(c => c.id === city_id);
  if (city) {
    return `(${city.name} dataset)`;
  }
  return '';
}

onMounted(async () => {
  const data = (await api.get('users/user/' + encodeURIComponent(route.params.email), authHeaders())).data;
  user.value = {
    email: data.email,
    name: data.name,
    role: data.role,
    password: '',
    account_id: data.account_id,
    can_send: data.can_send,
  };
  originalUser = { ...user.value };

  watchEffect(() => {
    dirty.value = Object.keys(user.value).some(key => user.value[key] !== originalUser[key]);
  });

  cities = (await api.get('datasets/cities', authHeaders())).data;

  accounts = (await api.get('users/accounts', authHeaders())).data;
  accountOptions.value = accounts.map((d) => ({ text: `${d.name} ${getCity(d.city_id)}`, value: d.id }));
  accountOptions.value = [{ text: 'Select account', value: null }, ...accountOptions.value];

  const roles = await api.get('users/roles', authHeaders());
  roleOptions.value = roles.data.map((d) => ({ text: d.name, value: d.id }));
  roleOptions.value = [{ text: 'Select role', value: null }, ...roleOptions.value];
});

async function onSave() {
  try {
    message.value = '';

    const updates = { email: user.value.email };
    if (originalUser.name !== user.value.name) updates.name = user.value.name;
    if (originalUser.role !== user.value.role) updates.role = user.value.role;
    if (originalUser.password !== user.value.password) updates.password = user.value.password;
    if (user.value.account_id != '') updates.account_id = user.value.account_id;

    await api.post('users/update', { user: updates }, authHeaders());
    user.value.password = '';  //in case we changed it
    originalUser = { ...user.value };
    //showToast.value = true;

    dirty.value = false;
  } catch (error) {
    message.value = error.message;
  }
}

const isSecurePassword = computed(() => {
  const password = user.value.password;
  if (!password) {
    return true; // Empty password is ok
  }
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return regex.test(password);
});
</script>

