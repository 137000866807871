function parsePlaceholders(placeholdersString) {
  let placeholders = [];
  if (placeholdersString) {
    placeholdersString.split(',@').forEach((d) => {
      const ph = d.startsWith('@') ? d : (d = '@' + d);
      const b = ph.split('=');
      placeholders.push({ key: b[0].trim(), value: b[1].trim()});
    });
  }
  return placeholders;
}
export default parsePlaceholders;