<template>
  <main role="main">
    <div v-if="rows" class="container-fluid">

      <div class="row">
        <div class="col d-flex align-items-end">
          <div class="small mb-0 text-muted">
            count {{ rows.length }}
          </div>
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-secondary my-2" @click="$router.push({ name: 'usersAdd' })">
            <i class="bi bi-plus"></i>
            New
          </button>
        </div>
      </div>

      <div class="d-none d-lg-block">
        <VueGoodTable :columns="columns" :rows="rows" :search-options="{ enabled: true }" :paginate="true" compactMode
          :group-options="{ enabled: false }" :theme="tableTheme" :sort-options="{
            enabled: true,
            initialSortBy: { field: 'name', type: 'desc' }
          }">
          <template #table-row="props">
            <span v-if="props.column.field == 'edit'">
              <button class="btn btn-secondary btn-sm" @click="onEditButtonClick(props.row)">Edit</button>
            </span>
            <span v-else-if="props.column.field == 'email'">
              <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </VueGoodTable>
      </div>

      <div class="d-lg-none">
        <VueGoodTable :columns="columnsSmall" :rows="rows" :search-options="{ enabled: true }" :paginate="true"
          compactMode :group-options="{ enabled: false }" :theme="tableTheme" :sort-options="{
            enabled: true,
            initialSortBy: { field: 'name', type: 'desc' }
          }">
          <template #table-row="props">
            <span v-if="props.column.field == 'edit'">
              <button class="btn btn-secondary btn-sm" @click="onEditButtonClick(props.row)">Edit</button>
            </span>
            <span v-else-if="props.column.field == 'name'">
              <div class="fw-bold text-warning">{{ props.row.account }}</div>
              <div class="fw-bold text-secondary">{{ props.row.name }}</div>
              <div><a :href="'mailto:' + props.row.email">{{ props.row.email }}</a></div>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </VueGoodTable>
      </div>

    </div>

    <div v-else>
      <p class="text-center">Loading...</p>
      <div class="overlay d-flex align-items-center justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

import { ref, onMounted, onBeforeUnmount } from 'vue';
import { api, authHeaders } from '../api';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next';

import { useUserStore } from '../stores/user';
const userStore = useUserStore();
const user = userStore.user;

const columns = [
  { label: 'Account', field: 'account' },
  { label: 'Name', field: 'name' },
  { label: 'Email', field: 'email' },
  { label: 'Role', field: 'role' },
  { label: 'City', field: 'city' },
  { label: 'Can send', field: 'can_send' },
  { label: '', field: 'edit', sortable: false }
];

const columnsSmall = [
  { label: 'Name', field: 'name' },
  { label: '', field: 'edit', sortable: false }
];

const rows = ref([]);
const tableTheme = ref('nocturnal bg-dark');

const encodeURL = (url) => {
  return encodeURIComponent(url).replace(/\./g, '%2E');
};

function onEditButtonClick(row) {
  if (row.email === user.email) {
    router.push({ name: 'userProfileEdit' });
  } else {
    router.push({ name: 'usersEdit', params: { email: encodeURL(row.email) } });
  }
}

onMounted(async () => {
  document.title = 'Foundry Users';

  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);

  const cities = await api.get('datasets/cities', authHeaders());
  const users = await api.get('users/list', authHeaders());
  const accounts = await api.get('users/accounts', authHeaders());

  rows.value = users.data.map(d => {
    return {
      email: d.email,
      name: d.name,
      role: d.role,
      city: d.city_id ? cities.data.find(c => c.id === d.city_id)?.name : '',
      account: d.account_id ? accounts.data.find(a => a.id === d.account_id)?.name : '',
      can_send: d.can_send ? 'Yes' : 'No'
    }
  });
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  tableTheme.value = prefersDarkTheme.matches ? 'nocturnal bg-dark' : '';
}
</script>

<style scoped>
:deep(.vgt-table.nocturnal) {
  background-color: #343a40 !important;
}
</style>