<template>
  <div class="logout">
    <div class="text-center">
      <img class="mt-5" style="width: 230px" src="/crosstown.svg" />
      <div class="logotype">Crosstown Foundry</div>
      <div class="mt-4">
        You have been logged off <a href="" @click="$router.push({ name: 'login'})">Crosstown Foundry</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
</script>

<style scoped>
html,
body {
  height: 100%;
}

.logotype {
  margin: 20px 0;
  font-size: 1.5em;
  font-weight: bolder;
}
</style>
