<template>
  <main v-if="user" role="main">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/users">Users</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ user.email }}</li>
      </ol>
    </nav>

    <div class="mb-2">
      <div class="mb-2 row">
        <div class="col-md-4">
          <label>Email:</label>
          <input v-model="user.email" type="email" class="form-control">
        </div>
        <div class="mt-3 col-md-4">
          <div v-if="user.email && !isValidEmail" class="form-text text-danger">Invalid email</div>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div class="row">
        <div class="col-md-4">
          <label>Name:</label>
          <input v-model="user.name" type="text" class="form-control">
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div class="row">
        <div class="col-md-4">
          <label>Password:</label>
          <input v-model="user.password" type="text" class="form-control" autocomplete="new-password">
        </div>
        <div class="mt-3 col-md-4">
          <div v-if="user.password && !isSecurePassword" class="form-text text-danger">Must be at least 8 characters long
            and contain at least one uppercase letter, one lowercase letter, and one number.</div>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div class="row">
        <div class="col-md-4">
          <label>Role:</label>
          <select v-model="user.role" class="form-select">
            <option v-for="option in roleOptions" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div class="row">
        <div class="col-md-4">
          <label>Account:</label>
          <select v-model="user.account_id" class="form-select">
            <option v-for="option in accountOptions" :value="option.value" :key="option.value">{{ option.text }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label class="me-2">Can send:</label>
      <input v-model="user.can_send" type="checkbox" class="form-check-input">
      <span class="ms-2 form-text text-danger"><i class="bi-exclamation-triangle"></i> Set only for authorized Crosstown
        LA
        editors!</span>
    </div>

    <div v-if="submitMessage" :class="{ 'alert': true, 'alert-success': submitted, 'alert-danger': !submitted }">
      {{ submitMessage }}
    </div>

    <button v-if="!submitted" class="btn btn-secondary" size="sm"
      :disabled='!user.email || !isSecurePassword || !isValidEmail' @click="onSave()">Add</button>
    <button v-else class="btn btn-secondary" size="sm" @click="onReset()">Add another</button>


  </main>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { api, authHeaders } from '../api';

// import { useRouter } from 'vue-router';
// const router = useRouter();

const user = ref({
  email: '',
  role: null,
  city: null,
  account_id: null,
  password: '',
  can_send: false,
});

const roleOptions = ref([]);
const accountOptions = ref([]);
let cities = [];

const submitMessage = ref('');
const submitted = ref(false);

async function onReset() {
  user.value = {
    email: '',
    role: null,
    city: null,
    account_id: null,
    password: '',
    can_send: false,
  };
  submitMessage.value = '';
  submitted.value = false;
}

async function onSave() {
  try {
    const response = await api.post('users/add', { user: user.value }, authHeaders());

    if (response.status === 201) {
      submitMessage.value = 'User added successfully';
      submitted.value = true;
    }
  } catch (error) {
    if (error.response && error.response.status === 409) {
      submitMessage.value = `User ${user.value.email} already exists`;
    } else {
      submitMessage.value = `Failed to add user ${error}`;
    }
  }
}

function getDataset(city_id) {
  const city = cities.find(c => c.id === city_id);
  if (city) {
    return `(${city.name})`;
  }
  return '';
}

const isSecurePassword = computed(() => {
  const password = user.value.password;
  if (!password) {
    return true; // Empty password is ok
  }
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  return regex.test(password);
});

const isValidEmail = computed(() => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(user.value.email);
});

onMounted(async () => {
  cities = (await api.get('datasets/cities', authHeaders())).data;

  const accounts = await api.get('users/accounts', authHeaders());
  accountOptions.value = accounts.data.map((d) => ({ text: `${d.name} ${getDataset(d.city_id)}`, value: d.id }));
  accountOptions.value = [{ text: 'Select account', value: null }, ...accountOptions.value];

  const roles = await api.get('users/roles', authHeaders());
  roleOptions.value = roles.data.map((d) => ({ text: d.name, value: d.id }));
  roleOptions.value = [{ text: 'Select role', value: null }, ...roleOptions.value];
});
</script>
