<script setup>
import { onMounted, onBeforeUnmount, computed} from 'vue';
import NavBar from './components/NavBar.vue';

import { useRoute } from 'vue-router';
const route = useRoute();

const isGuest = computed(() => {
  return route.meta.guest;
});

onMounted(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.addEventListener('change', updateTheme);
  updateTheme(prefersDarkTheme);
});

onBeforeUnmount(() => {
  const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
  prefersDarkTheme.removeEventListener('change', updateTheme);
});

function updateTheme(prefersDarkTheme) {
  document.body.dataset.bsTheme = prefersDarkTheme.matches ? 'dark' : 'light';
}
</script>

<template>
  <div>
    <NavBar v-if="!isGuest"/>
    <router-view/>
  </div>
</template>

